import { defineStore } from "pinia";
import { useUserStore } from './user.js';
import { playSendSound,playReceiveSound,playNotificationSound,playMessageNotificationSound } from "~/composables/sounds.composeable.js";
import { useChat } from "../composables/chat.composeable.js";


export const useChatStore = defineStore('chat',{
    state:()=>{
        return {
            list:[],
            messages:[],
            message_count:0,
            message_page:0,
            unread_messages:{},
            current_conversation:null,
            group_users:[],
            last_messages: {},
            channels:[],
            onlineUsers:{}
        }
    },
    actions:{
        isCurrentConversation(){
            if(this.current_conversation == null || this.current_conversation == {} || this.current_conversation == ""){
                return false
            }
            return true
        },
        mergeArraysByIdAndUserId(arr1, arr2) {
            const mergedMap = new Map();
          
            // Add all items from array1 using 'id' as key
            arr1.forEach(item => {
              mergedMap.set(item.id, { ...item });
            });
          
            // Add or merge items from array2 using 'user_id' as key
            arr2.forEach(item => {
              const id = item.user_id;
              if (mergedMap.has(id)) {
                mergedMap.set(id, { ...mergedMap.get(id), ...item });
              } else {
                mergedMap.set(id, { ...item });
              }
            });
          
            return Array.from(mergedMap.values());
        },
        setList(dataList){
            this.list = dataList;
            this.list.forEach(con => {
                if(con.last_message.length>0){
                    this.setLastMessage(con.id,con.last_message[0])
                }
            });
        },
        setCurrentConversation(conversation_id){
            this.messages = []
            this.message_count = 0
            this.message_page = 0
            const index = this.list.findIndex((con)=>con.id == conversation_id)
            this.current_conversation = this.list[index]
        },
        setMessages(messages){
            let sortedMessagesList = this.sortMessages(messages)
            this.messages.unshift(...sortedMessagesList)
            const lastMessage = this.messages[this.messages.length-1]
            this.messages.forEach(m => {
                this.last_messages[m.conversation_id] = m;
            });
        },
        setLastMessage(conversationId,message){
            this.last_messages[conversationId] = message;
        },
        async addMessage(message){
            try {
                const user = useUserStore()
                const { readMessage } = await useChat()
    
                if(message){
    
                     // if current conversation not open and not send by me.
                     if(!this.current_conversation && (message.send_by != user.profile.id) ){
                        this.last_messages[message.conversation_id] = message;
                         if(message.conversation_id in this.unread_messages){
                                this.unread_messages[message.conversation_id].push(message.id)
                            }
                            else{
                                this.unread_messages[message.conversation_id] = [message.id]
                            }
                            if(message.send_by){
                                playMessageNotificationSound()
                            }
                        return
                    }
    
                    if(message.conversation_id == this.current_conversation.id){
                        this.messages.push(message)
                        this.last_messages[message.conversation_id] = message;
                        if(message.send_by != user.profile.id){
                            playReceiveSound()
                            await readMessage(message.id) 
                        }
                    }
                    else if((message.send_by == user.profile.id) && (message.conversation_id != this.current_conversation.id)){
                        this.last_messages[message.conversation_id] = message;
                    }
                    else if((message.send_by != user.profile.id) && (message.conversation_id != this.current_conversation.id)){
                        this.last_messages[message.conversation_id] = message;
        
                        if(message.conversation_id in this.unread_messages){
                            this.unread_messages[message.conversation_id].push(message.id)
                        }
                        else{
                            this.unread_messages[message.conversation_id] = [message.id]
                        }
                        playNotificationSound()
                    }
                }
            } catch (error) {
                console.log('Error add message store',error)
            }
        

        },
        updateMeeting(messageId, message){
            const index = this.messages.findIndex(m=>m.id == messageId)
            this.messages[index]=message
        },      
        deleteMessage(conversationId,messageId){
            if(this.messages.length > 0){
                const index = this.messages.findIndex(message => message.id === messageId);
                if(index){
                    this.messages.splice(index,1)
                    this.message_count--
                    const messagesArray = this.messages.length - 1 
                    this.setLastMessage(conversationId,this.messages[messagesArray])
                    return
                }
                if(this.messages.length){
                     let lastMessage = this.last_messages[conversationId]
                    lastMessage.message = "This message was Deleted"
                    this.setLastMessage(conversationId,lastMessage)
                }
            }

            if(this.messages.length<=0){
                let lastMessage = this.last_messages[conversationId]
                lastMessage.message = "This message was Deleted"

                if(conversationId in this.unread_messages){
                   
                    const index = this.unread_messages[conversationId].findIndex(id => id === messageId);
                    if(index){
                        this.unread_messages[conversationId].splice(index,1)
                    }
                    if(this.unread_messages[conversationId].length == 0){
                        delete this.unread_messages[conversationId]
                    }
                }

                this.setLastMessage(conversationId,lastMessage)

                return
            }
 
        },
        updateMessage(conversationId,messageId,message){
            if(message.type == "meeting"){
                this.updateMeeting(messageId,message)
            }
        },
        removeMesssage(index){
            this.messages.splice(index,1)
        },
        setStaus(status){
            this.current_conversation.status = status
        },
        setMessagesTotalCount(count){
            this.message_count = count
        },
        setMessagePage(page){
            if(page == 1 && this.messages.length > 0){
                this.messages = [];
            }
            this.message_page = page
        },
        sortMessages(messages){
            const sorted = messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            return sorted
        },
        addGroup(group){
            group.last_message = []
            this.list.unshift(group)
        },
        updateGroupDetail(group_id,group){
            const Index = this.list.findIndex(r => r.id == group_id)
            this.list[Index].title = group.title
        },
        deleteGroup(group_id){
            const Index = this.list.findIndex(r => r.id == group_id)
            this.list.slice(Index,1)
        },
        setChannels(channels){
            this.channels = channels
        },
        clearUnreadMessages(conversation_id){
            if(conversation_id in this.unread_messages){
                delete this.unread_messages[conversation_id]
            }
        },
        updateProfile(id,key){
            const index = this.list.findIndex(m=>m.id === id)
            this.list[index].profile = key
            if(this.current_conversation.id === id){
                this.current_conversation.profile = key
            }
        },
        resetChatStore(){
            this.list=[],
            this.messages=[],
            this.message_count=0,
            this.message_page=0,
            this.unread_messages={},
            this.current_conversation=null,
            this.group_users=[],
            this.last_messages= {},
            this.channels=[],
            this.onlineUsers = null
        },
        setOnlineUsers(members){
            this.onlineUsers = members
        },
        addOnlineUser(member){
            this.onlineUsers[member.id] = null
        },
        removeOnlineUser(member){
            delete this.onlineUsers[member.id]
        },
        isUserOnline(userId){
            return this.onlineUsers[userId] ? 'Online' : 'Offline'
        }
    },
    getters:{
        getMessages: (state) => {
            const sorted = state.messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            return sorted
        },
        getUnreadMessagesCount:(state)=>{
        },
        getList(state){
            return state.list
        },
        getUnreadCount(state){
            return conversationId
        },
        getLastMessage(conversationId){
            return this.last_message[conversationId]
        },
    }
})