import { Howl } from 'howler'

let sendSound = new Howl({
  src: ['https://ik.imagekit.io/hireheroesstaging/sounds/message_sent.mp3'],
  onloaderror: function(id, error) {
    console.error('Failed to load sound:', error);
  },
  onplayerror: function(id, error) {
    console.error('Failed to play sound:', error);
  }
});

let receiveSound = new Howl({
  src: ['https://ik.imagekit.io/hireheroesstaging/sounds/receive_message.mp3'],
  onloaderror: function(id, error) {
    console.error('Failed to load sound:', error);
  },
  onplayerror: function(id, error) {
    console.error('Failed to play sound:', error);
  }
});

let notificationSound = new Howl({
  src: ['https://ik.imagekit.io/hireheroesstaging/sounds/notif.mp3'],
  onloaderror: function(id, error) {
    console.error('Failed to load sound:', error);
  },
  onplayerror: function(id, error) {
    console.error('Failed to play sound:', error);
  }
});

let messageNotificationSound = new Howl({
  src: ['https://ik.imagekit.io/hireheroesstaging/sounds/notification_message.mp3'],
  onloaderror: function(id, error) {
    console.error('Failed to load sound:', error);
  },
  onplayerror: function(id, error) {
    console.error('Failed to play sound:', error);
  }
});

export const playSendSound = () => {
  sendSound.play();
}

export const playReceiveSound = () => {
  receiveSound.play();
}

export const playNotificationSound = () => {
  notificationSound.play();
}

export const playMessageNotificationSound = () => {
  messageNotificationSound.play();
}
