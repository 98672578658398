import { defineStore } from "pinia";
import { playNotificationSound } from "~/composables/sounds.composeable.js";
export const useNotificationStore = defineStore('notification', {
    state: () => {
        return {
            notifications: [],
            count:0,
            page:0,
            total:0
        }
    },
    actions: {
        setNotification(array){
            this.notifications = array.notifications
            this.total = array.notificationsCount
            this.page = array.page

        },
        addNotification(data){
            if(data.length > 0){
                this.notifications.unshift(...data)
            }
            else{
                this.notifications.unshift(data)
            }
            this.count += 1
            playNotificationSound()
        },
        removeNotification(notificationId){
            const index = this.notifications.findIndex(r => r.id == notificationId)
            if(index){
                this.notifications.splice(index,1)
                this.count -= 1
            }
        },
        updateNotification(notificationId,notification){
            const index = this.notifications.findIndex(r => r.id == notificationId)
            if(index != -1){
                this.notifications[index] = notification
            }
        },
        resetNotificationStore(){
            this.notifications = []
            this.count = 0
            this.page = 0
            this.total = 0
        },
        updateAllNotification(){
            this.notifications.forEach(n=>{
                return n.is_read == false
            })
        }
    },
    getters:{
        getUnreadCount(){
            return this.notifications.filter(r => r.is_read === false).length
        }
    }
})