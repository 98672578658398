import Pusher from 'pusher-js'
import { useRuntimeConfig } from '#app'; 

const config = useRuntimeConfig();
const BASE_PATH  = config.public.api_url
const token = sessionStorage.getItem('token') || localStorage.getItem('token')
const pusher = new Pusher(config.public.pusher_key, {
    cluster: config.public.pusher_cluster,
    // channelAuthorization:{
    //     endpoint:`${BASE_PATH}/pusher/auth`
    // },
    authEndpoint: `${BASE_PATH}/pusher/auth`,
    auth: {
        headers: {
            authorization: `Bearer ${token}`,
        },
      },
      forceTLS:true
})


export const usePusher = async () =>{

    const chatStore = useChatStore()
    const notificationStore = useNotificationStore()
    const userStore = useUserStore()
    // const {getList} = await useChat()
    
    const subscribeCommonChannel = async () => {

        const centralChannel = pusher.subscribe('presence-central')

        centralChannel.bind('notification',async (notification)=>{
            if(notification.user_id == userStore.profile.id){
                notificationStore.addNotification(notification)
            }
        })

        centralChannel.bind('subscribe-channel',async (data)=>{
            if(data.user_id == userStore.profile.id){
                subscribeSingleChannel(data.conversation_id)
                await getList()
            }
        })

        centralChannel.bind('unsubscribe-channel',async (data)=>{
            if(data.user_id == userStore.profile.id){
                unSubscribeSingleChannel(data.conversation_id)
                await getList()
                if(chatStore.current_conversation.id == data.conversation_id){
                    chatStore.setCurrentConversation(null)
                }
            }
        })

        centralChannel.bind('pusher:subscription_succeeded', (members) => {
            chatStore.setOnlineUsers(members.members)
        });

        centralChannel.bind('pusher:member_added', (member) => {
            chatStore.addOnlineUser(member)
          });
        
        centralChannel.bind('pusher:member_removed', (member) => {
            chatStore.removeOnlineUser(member)
          });
          
        centralChannel.bind('pusher:subscription_error', (status) => {
            console.error('Subscription error:', status);
          });

        console.log('subscribeCommonChannel',centralChannel)

    }

    const unSubscribeCommonChannel = async () => {
        pusher.unsubscribe('private-central')
    }

    const subscribeToChannels = async (channels) => {

        await subscribeCommonChannel()

        channels.forEach(name => {
            console.log(`subscribing to ${name}`)
            let channel = pusher.subscribe(`private-${name}`)

            channel.bind('messages',async (message)=>{
                console.log('add message pusher:',message)
                await chatStore.addMessage(message)
            })

            channel.bind('delete-message',(data)=>{
                const {conversationId,messageId} = data
                chatStore.deleteMessage(conversationId,messageId)
            })

            channel.bind('update-message',(data)=>{
                console.log('update-message',data)
                const {conversation_id,message_id,message} = data
                chatStore.updateMessage(conversation_id,message_id,message)
            })

            channel.bind('user-typing', function(data) {
                handleUserTyping(channelName, data);
            });

        });

        return
    }

    const unsubscribeTochannels = async (channels) =>{

        if(channels.length <= 0) return

        channels.forEach(name => {
            pusher.unsubscribe(`private-${name}`);
        });

        return
    }

    const subscribeSingleChannel = async (channelName) => {

        let channel = pusher.subscribe(`private-${channelName}`)
        
        channel.bind('messages',async (message)=>{
            await chatStore.addMessage(message)
        })
        
        channel.bind('delete-message',(data)=>{
            const {conversationId,messageId} = data
            chatStore.deleteMessage(conversationId,messageId)
        })
        
        channel.bind('update-message',(data)=>{
            const {conversation_id,message_id,message} = data
            chatStore.updateMessage(conversation_id,message_id,message)
        })
        
        channel.bind('user-typing', function(data) {
            handleUserTyping(channelName, data);
        });
        console.log('subscribeSingleChannel',channelName,channel)
        return
    }

    const unSubscribeSingleChannel = async (channelName) => {
        pusher.unsubscribe(`private-${channelName}`);
        return
    }

    const getList = async () => {
        try {
            const {data:list,message} = await useAxiosGet('chat/list')
            chatStore.setList(list)
            return list
        } catch (error) {
            console.log("Dashboard Error : ",error)
        }
    }

    return{
        subscribeCommonChannel,
        unSubscribeCommonChannel,
        subscribeToChannels,
        unsubscribeTochannels,
        subscribeSingleChannel,
        unSubscribeSingleChannel
    }

}
